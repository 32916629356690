// Firebase configuration file
// Replace the placeholders with your actual Firebase project configuration

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDZ2PLnqV67MZUmilUGWgqARQFvUGLUzBM",
  authDomain: "styline-fa19f.firebaseapp.com",
  projectId: "styline-fa19f",
  storageBucket: "styline-fa19f.firebasestorage.app",
  messagingSenderId: "690816261655",
  appId: "1:690816261655:web:8b9c0a3f2074e0e2b20652",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
